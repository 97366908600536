@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


* {
  box-sizing: border-box;
}


input:-webkit-autofill {  
  -webkit-text-fill-color: #ededed !important;  
  -webkit-box-shadow: 0 0 0px 1000px transparent  inset !important;  
  background-color:transparent;  
  background-image: none;  
  transition: background-color 50000s ease-in-out 0s;  
}

/* input {  
  background-color:transparent;  
  caret-color: #fff;
}   */