.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  min-width: 1440px;
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

:root {
  --background-rgb: 2 6 23;
  --background-light-rgb: 30 41 59;

  --border-rgb: 255 255 255;
  --border: 1px solid rgb(var(--border-rgb) / 20%);

  --hyperplexed-main-rgb: 41 121 255;
  --hyperplexed-main-light-rgb: 56 182 255;
  --hyperplexed-secondary-rgb: 42 252 152;

  --card-size: 700px;
  --font-size: 1rem;
  --logo-size: calc(var(--card-size) * 0.3);
}

body {

  /* background: rgb(var(--background-rgb));
  height: 100vh;
  display: flex;
  justify-content: center;

  font-family: 'Noto Sans', sans-serif; */

  --linear-yellow: linear-gradient(91.29deg, #FF5424 1.82%, #FFC806 98.9%);
  --linear-border: linear-gradient(0deg, #141218, #141218),
    linear-gradient(0deg, rgba(208, 188, 255, 0.12), rgba(208, 188, 255, 0.12));
}

.card-track {
  height: calc(90vh - 85px);
  width: var(--card-size);
  display: flex;
  align-items: top;
  position: relative;
}

.card-wrapper {
  width: 100%;
  position: relative;
}

.card {
  display: flex;
  align-items: center;
  justify-content: center;
  /* aspect-ratio: 1; */
  /* height: 670px;
  width: 100%; */
  height: calc(90vh - 105px);
  min-width: 600px;

  position: relative;
  margin: 1rem;
  border-radius: 2rem;
  overflow: hidden;
  cursor: pointer;
}

.card-image {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 4;
}

.card-image>img {
  width: var(--logo-size);
}

.card-gradient {
  height: calc(90vh - 105px);
  width: 100%;
  position: absolute;
  background: radial-gradient(rgb(var(--background-light-rgb)) 40%,
      rgb(var(--hyperplexed-main-rgb)) 50%,
      rgb(var(--hyperplexed-main-light-rgb)),
      rgb(var(--hyperplexed-secondary-rgb)));
  mix-blend-mode: darken;
  pointer-events: none;
  z-index: 3;
}

.card-letters {
  --x: 0px;
  --y: 0px;
  position: absolute;
  left: 0px;
  top: 0px;
  height: calc(90vh - 105px);
  width: 100%;
  color: white;
  font-size: var(--font-size);
  font-weight: 500;
  word-wrap: break-word;
  opacity: 0;
  transition: opacity 400ms;
  -webkit-mask-image: radial-gradient(calc(var(--card-size) * 0.8) circle at var(--x) var(--y),
      rgb(255 255 255) 20%,
      rgb(255 255 255 / 25%),
      transparent);
  scale: 1.03;
}

.card:hover .card-letters {
  opacity: 1;
}

.over-card:hover~.card-letters {
  opacity: 1;
}

@media(max-width: 600px) {
  :root {
    --card-size: 340px;
  }

  .card {
    border-radius: 1rem;
  }
}

/* -- Extra Styles -- */

.card-track:before,
.card-track:after {
  content: "";
  height: 110vh;
  width: 1px;
  position: absolute;
  translate: 0% 0%;
}


.card-track:before {
  left: -1px;
  border-left: var(--border);
}

.card-track:after {
  right: -1px;
  border-right: var(--border);
}


.card-wrapper:before {
  content: "";
  width: 150vw;
  position: absolute;
  left: 50%;
  translate: -50%;
}

.card-wrapper:after {
  content: "";
  width: 100%;
  position: absolute;
  left: 50%;
  translate: -50%;
}

.card-wrapper:before {
  top: -1px;
  border-top: var(--border);
}

.card-wrapper:after {
  bottom: -1px;
  border-bottom: var(--border);
}

.card-corners {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 3;
  pointer-events: none;
}

.card-corners>.card-corner {
  display: block;
  height: 9px;
  width: 1px;
  position: absolute;
  background-color: white;
}

.card-corners>.card-corner:after {
  content: "";
  width: 9px;
  height: 1px;
  position: absolute;
  left: -4px;
  top: 4px;
  background-color: white;
}

.card-corners>.card-corner:nth-child(1) {
  left: -1px;
  top: -5px;
}

.card-corners>.card-corner:nth-child(2) {
  right: -1px;
  top: -5px;
}

.card-corners>.card-corner:nth-child(3) {
  right: -1px;
  bottom: -5px;
}

.card-corners>.card-corner:nth-child(4) {
  left: -1px;
  bottom: -5px;
}

/* -- Links -- */

#links {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: absolute;
  bottom: 0px;
  left: 0px;
  z-index: 100;
  padding: 1rem;
}

.meta-link {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.meta-link> :is(span, a) {
  font-family: "Rubik", sans-serif;
  font-size: 1.25rem;
  color: white;
}

.meta-link>.label {
  text-align: right;
}

.meta-link>a {
  text-decoration: none;
  outline: none;
}

.meta-link>a.source {
  color: rgb(94, 106, 210);
}

.meta-link>a:is(:hover, :focus)>i {
  color: white;
}

.meta-link>a.youtube {
  color: rgb(239, 83, 80);
}

.meta-link>a.youtube>i {
  padding-top: 0.2rem;
}

.meta-link>a>i {
  height: 1rem;
  line-height: 1rem;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


@font-face {
  font-family: 'TT Firs Neue';
  src: url('./fonts/tt_firs_neue/TT Firs Neue Trial Light.ttf') format('truetype');
  font-weight: 300;
  /* normal */
  font-style: normal;
}

@font-face {
  font-family: 'TT Firs Neue';
  src: url('./fonts/tt_firs_neue/TT Firs Neue Trial Regular.ttf') format('truetype');
  font-weight: 400;
  /* normal */
  font-style: normal;
}

@font-face {
  font-family: 'TT Firs Neue';
  src: url('./fonts/tt_firs_neue/TT Firs Neue Trial Medium.ttf') format('truetype');
  font-weight: 500;
  /* Bold */
  font-style: normal;
}

@font-face {
  font-family: 'TT Firs Neue';
  src: url('./fonts/tt_firs_neue/TT Firs Neue Trial DemiBold.ttf') format('truetype');
  font-weight: 600;
  /* Bold */
  font-style: normal;
}

@font-face {
  font-family: 'TT Firs Neue';
  src: url('./fonts/tt_firs_neue/TT Firs Neue Trial Bold.ttf') format('truetype');
  font-weight: 700;
  /* Bold */
  font-style: normal;
}

@font-face {
  font-family: 'TT Firs Neue';
  src: url('./fonts/tt_firs_neue/TT Firs Neue Trial ExtraBold.ttf') format('truetype');
  font-weight: 800;
  /* ExtraBold */
  font-style: normal;
}

.turret-road-extralight {
  font-family: "Turret Road", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.turret-road-light {
  font-family: "Turret Road", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.turret-road-regular {
  font-family: "Turret Road", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.turret-road-medium {
  font-family: "Turret Road", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.turret-road-bold {
  font-family: "Turret Road", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.turret-road-extrabold {
  font-family: "Turret Road", sans-serif;
  font-weight: 800;
  font-style: normal;
}


.header-gradient::before {
  content: ' ';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(0deg, #141218, #141218),
    linear-gradient(0deg, rgba(208, 188, 255, 0.12), rgba(208, 188, 255, 0.12));
  z-index: 9;
}

.header-gradient::after {
  content: ' ';
  position: absolute;
  left: 346px;
  bottom: 0;
  width: 11px;
  height: 1px;
  background: #fff;
  z-index: 10;
}

.left-nav-gradient::before {
  content: ' ';
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 1px;
  height: 100%;
  background: linear-gradient(0deg, #141218, #141218),
    linear-gradient(0deg, rgba(208, 188, 255, 0.12), rgba(208, 188, 255, 0.12));
  z-index: 9;
}


.left-nav-gradient::after {
  content: ' ';
  position: absolute;
  right: 0;
  top: 0;
  width: 1px;
  height: 6px;
  background: #fff;
  z-index: 10;
}

.portal-item {
  position: relative;
  background-image: var(--linear-border);
}

.portal-item::before {
  content: '';
  position: absolute;
  inset: 1px;
  z-index: 9;
  border-radius: inherit;
  /*important*/
  background: #000;
}

.slick-wrapper {
  position: relative;
  background-image: var(--linear-border);
}

.slick-wrapper::before {
  content: '';
  position: absolute;
  inset: 1px;
  z-index: 9;
  border-radius: inherit;
  /*important*/
  background: #000;
}

.nav-item:hover{
  position: relative;
  background: linear-gradient(91.29deg, #FF5424 1.82%, #FFC806 98.9%);
}

.nav-item:hover::before{
  content: '';
  position: absolute;
  inset: 1px;
  background: linear-gradient(75.96deg, #14181F 0%, #272A31 100%);
  border-radius: inherit;
}

.wallet-adapter-button:not([disabled]):hover {
  background: none !important;
}

.wallet-adapter-button-trigger {
  background: none !important;
}
